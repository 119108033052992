const COMPETITION_ENTRY_TRUE = 'frontend/competition-entry/COMPETITION_ENTRY_TRUE';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMPETITION_ENTRY_TRUE:
      return [...state, action.payload.competitionId];
    default:
      return state;
  }
}
