const FETCH_COMPETITIONS = 'frontend/competitions/FETCH_COMPETITIONS';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMPETITIONS:
      return action.payload;
    default:
      return state;
  }
}
