import get from 'lodash/get';
import omit from 'lodash/omit';

import store from '../store';
import { SET_USER_LIBRARY } from './user-library';
import { SET_STEAM_LIBRARY } from './steam-library';
import { SET_USER_TRAITS } from './user-traits';

export const LOGIN_SUCCESS = 'frontend/auth/LOGIN_SUCCESS';
export const SIGNUP_SUCCESS = 'frontend/auth/SIGNUP_SUCCESS';
export const LOGIN_ERROR = 'frontend/auth/LOGIN_ERROR';
export const SIGNUP_ERROR = 'frontend/auth/SIGNUP_ERROR';
export const CHALLENGE = 'frontend/auth/CHALLENGE';
export const MAGIC_SUMMONED = 'frontend/auth/MAGIC_SUMMONED';
export const RESET_AUTH = 'frontend/auth/RESET_AUTH';
export const REFRESH_AUTH = 'frontend/auth/REFRESH_AUTH';
export const RESET_SUCCESS = 'frontend/auth/RESET_SUCCESS';
export const LOGOUT = 'frontend/auth/LOGOUT';
export const CHANGE_PASSWORD_SUCCESS = 'frontend/auth/CHANGE_PASSWORD_SUCCESS';
export const SEND_CONFIRM_EMAIL = 'frontend/auth/SEND_CONFIRM_EMAIL';
export const CHECK_CONFIRMED_EMAIL = 'frontend/auth/CHECK_CONFIRMED_EMAIL';
export const CHANGE_EMAIL_SUCCESS = 'frontend/auth/CHANGE_EMAIL_SUCCESS';
export const NEW_CONFIRMED_EMAIL = 'frontend/auth/NEW_CONFIRMED_EMAIL';
export const SAVE_STEAM_PROFILE = 'frontend/auth/SAVE_STEAM_PROFILE';
export const SAVE_STEAM_PROFILE_ERROR = 'frontend/auth/SAVE_STEAM_PROFILE_ERROR';
export const EMAIL_CHANGE_CONFIRM = 'frontend/auth/EMAIL_CHANGE_CONFIRM';
export const DETECT_KY = 'frontend/auth/DETECT_KY';
export const DETECT_HONEY = 'frontend/auth/DETECT_HONEY';
export const SET_LOGGED_OUT_EMAIL = 'frontend/auth/SET_LOGGED_OUT_EMAIL';
export const SET_NEWSLETTER = 'frontend/auth/SET_NEWSLETTER';
export const SET_LOCAL_NEWSLETTER_CONSENT = 'frontend/auth/SET_LOCAL_NEWSLETTER_CONSENT';
export const SAVE_DOB = 'frontend/auth/SAVE_DOB';
export const SAVE_SITE_PREFERENCES = 'frontend/auth/SAVE_SITE_PREFERENCES';

export const initialState = {
  sentEmail: false,
  authenticated: false,
  preferences: {},
};

export default function reducer(state = initialState, action) {
  let payload = action.payload;

  // Catch user library and user traits and send to separate reducers
  // Slighty hacky solution, could be refactored with middleware or a special method
  // with separate dispatches
  if (get(action, 'type', '').includes('frontend/auth')) {
    if (get(action, 'payload.library')) {
      setTimeout(() => {
        store.dispatch({ type: SET_USER_LIBRARY, payload: get(action, 'payload.library') });
      }, 1);

      payload = omit(payload, ['library']);
    }

    if (get(action, 'payload.steamLibrary')) {
      setTimeout(() => {
        store.dispatch({ type: SET_STEAM_LIBRARY, payload: get(action, 'payload.steamLibrary') });
      }, 1);

      payload = omit(payload, ['steamLibrary']);
    }

    if (get(action, 'payload.user_traits')) {
      setTimeout(() => {
        store.dispatch({ type: SET_USER_TRAITS, payload: get(action, 'payload.user_traits') });
      }, 1);

      payload = omit(payload, ['user_traits']);
    }
  }

  switch (action.type) {
    case LOGIN_SUCCESS:
    case SIGNUP_SUCCESS:
      return {
        ...state,
        authenticated: true,
        error: null,
        challenge: null,
        magicSuccess: !!payload.isMagic,
        magicSummoned: false,
        ...payload,
      };
    case LOGIN_ERROR:
    case SIGNUP_ERROR:
      return {
        ...state,
        authenticated: false,
        challenge: null,
        error: payload,
        magicSuccess: null,
        magicSummoned: false,
      };
    case MAGIC_SUMMONED:
      return {
        ...state,
        authenticated: false,
        challenge: null,
        error: null,
        magicSummoned: true,
      };
    case CHALLENGE:
      return {
        ...state,
        authenticated: false,
        error: null,
        challenge: payload,
        magicSuccess: null,
        magicSummoned: false,
      };
    case RESET_AUTH:
      return { ...state, error: null, challenge: null, magicSuccess: null, magicSummoned: null };
    case REFRESH_AUTH:
      return {
        ...state,
        authenticated: true,
        ...payload,
      };
    case LOGOUT:
      return { authenticated: false };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, ...payload, passwordSuccess: payload };
    case SEND_CONFIRM_EMAIL:
      return { ...state, sentEmail: payload };
    case SET_LOGGED_OUT_EMAIL:
      return { ...state, email: payload };
    case CHECK_CONFIRMED_EMAIL:
      return { ...state, ...payload };
    case DETECT_KY:
      return { ...state, ...payload };
    case DETECT_HONEY:
      return { ...state, ...payload };
    case NEW_CONFIRMED_EMAIL:
      return { ...state, email_confirmed: true };
    case CHANGE_EMAIL_SUCCESS:
      return { ...state, ...payload, emailSuccess: payload };
    case SAVE_STEAM_PROFILE:
      return { ...state, ...payload };
    case SET_LOCAL_NEWSLETTER_CONSENT:
      return { ...state, localNewsletterConsent: payload };
    case SET_NEWSLETTER:
      return payload;
    case SAVE_STEAM_PROFILE_ERROR:
      return state;
    case SAVE_DOB:
      return { ...state, ...payload };
    case SAVE_SITE_PREFERENCES:
      return { ...state, preferences: payload };
    default:
      return state;
  }
}
