import anonymous from './reducers/anonymous';
import auth from './reducers/auth';
import adverts from './reducers/adverts';
import cart from './reducers/cart';
import checkout from './reducers/checkout';
import modal from './reducers/modal';
import feedbackPopups from './reducers/feedback-popups';
import orders from './reducers/orders';
import pickAndMix from './reducers/pickandmix';
import redeem from './reducers/redeem';
import redeemError from './reducers/redeem-error';
import ageGating from './reducers/age-gating';
import emailSubscribe from './reducers/email-subscribe';
import voucher from './reducers/voucher';
import vouchers from './reducers/vouchers';
import bundleReminder from './reducers/bundle-reminders';
import facebook from './reducers/facebook';
import fandom from './reducers/fandom';
import initial from './reducers/initial-load';
import accountConfirm from './reducers/account-confirm';
import redeemConfirm from './reducers/redeem-confirm';
import passwordReset from './reducers/password-reset';
import nativeRates from './reducers/native-rates';
import recaptcha from './reducers/recaptcha';
import errorMessages from './reducers/error-messages';
import recommendations from './reducers/recommendations';
import splitTest from './reducers/split-test';
import megaMenu from './reducers/mega-menu';
import universalError from './reducers/universal-error';
import wishlist from './reducers/wishlist';
import notificationBanner from './reducers/notification-banner';
import loginActions from './reducers/login-actions';
import atok from './reducers/atok';
import ubisoftError from './reducers/ubisoft-error';
import bundleBlast from './reducers/bundle-blast';
import language from './reducers/languages';
import recentlyViewed from './reducers/recently-viewed';
import opportunities from './reducers/opportunities';
import flashSale from './reducers/flash-sale';
import checkoutMessage from './reducers/checkout-message';
import voucherMessage from './reducers/voucher-message';
import cartMessage from './reducers/cart-message';
import unsubsribeMessage from './reducers/unsubscribe-message';
import optinMessage from './reducers/optin-message';
import deliveries from './reducers/deliveries';
import trustpilot from './reducers/trustpilot';
import mystery from './reducers/mystery';
import apipayment from './reducers/apipayment';
import twoFactor from './reducers/two-factor';
import publishers from './reducers/publishers';
import sales from './reducers/sales';
import freeProduct from './reducers/free-product';
import exitIntentState from './reducers/exit-intent';
import deviceFingerprint from './reducers/device-fingerprint';
import cookie from './reducers/cookie';
import starDeal from './reducers/star-deal';
import google from './reducers/google';
import selectableFreeProducts from './reducers/selectable-free-products';
import siteTheme from './reducers/site-theme';
import siteBrand from './reducers/site-brand';
import accountPreference from './reducers/account-preference-centre';
import gift from './reducers/gift';
import epic from './reducers/epic';
import franchises from './reducers/franchises';
import liveDisplayTypes from './reducers/live-display-types';
import userTraits from './reducers/user-traits';
import userLibrary from './reducers/user-library';
import steamLibrary from './reducers/steam-library';
import notifications from './reducers/notifications';
import checkoutEmailSignUp from './reducers/checkout-email-signup';
import competitions from './reducers/competitions';
import competitionEntry from './reducers/competition-entry';

export default {
  accountPreference,
  adverts,
  ageGating,
  anonymous,
  apipayment,
  atok,
  auth,
  bundleBlast,
  bundleReminder,
  cart,
  cartMessage,
  checkout,
  checkoutEmailSignUp,
  checkoutMessage,
  competitionEntry,
  competitions,
  confirm: accountConfirm,
  cookie,
  deliveries,
  deviceFingerprint,
  emailSubscribe,
  epicURL: epic,
  errorMessages,
  exitIntentState,
  facebook,
  fandom,
  feedbackPopups,
  flashSale,
  franchises,
  freeProduct,
  gift,
  google,
  initial,
  language,
  liveDisplayTypes,
  loginActions,
  megaMenu,
  modal,
  mystery,
  nativeRates,
  notificationBanner,
  notifications,
  opportunities,
  optin: optinMessage,
  orders,
  password_reset: passwordReset,
  pickAndMix,
  publishers,
  recaptcha,
  recentlyViewed,
  recommendations,
  redeem,
  redeemConfirmStatus: redeemConfirm,
  redeemError,
  sales,
  selectableFreeProducts,
  siteBrand,
  siteTheme,
  splitTest,
  starDeal,
  steamLibrary,
  trustpilot,
  twoFactor,
  ubisoftError,
  universalError,
  unsubscribe: unsubsribeMessage,
  userLibrary,
  userTraits,
  voucher,
  voucherMessage,
  vouchers,
  wishlist,
};
